<template>
  <div class="container-fluid" v-if="$route.meta.requiresAuth">
    <div class="row application-bg" v-if="this.basepathstatus == 2">
      <GoaSidebarTabs />
      <main class="col-md-9 col-lg-9 ms-sm-auto layout-content-outer">
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
    <div class="row application-bg" v-if="this.basepathstatus == 3">
      <AssamSidebarTabs />
      <main class="col-md-9 col-lg-9 ms-sm-auto layout-content-outer">
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
    <div class="row application-bg" v-if="this.basepathstatus == 1">
      <AssamSidebarTabs />
      <main class="col-md-9 col-lg-9 ms-sm-auto layout-content-outer">
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>
</template>

<script>
import GoaSidebarTabs from "./components/GoaSidebarTabs.vue";
import AssamSidebarTabs from "./components/AssamSidebarTabs.vue";

export default {
  name: "App",
  components: {
    GoaSidebarTabs: GoaSidebarTabs,
    AssamSidebarTabs: AssamSidebarTabs,
  },
  data() {
    return {
      baseUrl: window.location.origin,
      basepathstatus: "",
    };
  },
  mounted() {
    if (this.baseUrl == "https://goa.cmconnect.in") {
      this.basepathstatus = 2;
    } else if (this.baseUrl == "https://assam.cmconnect.in") {
      this.basepathstatus = 3;
    } else {
      this.basepathstatus = 1;
    }
  },
};
</script>
